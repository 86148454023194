.user-info-form {
    padding: 0;
    margin: 0 auto;
    background-color: #fff;
    align-self: flex-start;
}

.user-info-form h2 {
    margin: 0 auto;
    text-align: center;
}

.user-info-form p {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
}

.user-info-form button {
    background-color: hsla(214.7,33.6%,48.43%,1) !important;    
    font-size: 1rem;
    font-weight: 400;
}

.form-group label {
    cursor: pointer;
} 



