/* src/pages/IntroPage.css */
.intro-page {
  display: flex;
  align-self: center;
  justify-self: center;
  flex-direction: column;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    border: 2px double hsla(214.7,33.6%,48.43%,1);
  }
  
  .intro-page h2 {
    margin: 0 auto;
    font-weight: 400;
  }
  .intro-page h5 {
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .intro-page p {
    font-weight: 400;
    margin-bottom: 15px;
  }

  .intro-page p sup {
    font-size: 0.6rem;
  }
  
  .intro-page Button {
    width: 60%;
    margin-top: 10px;
    align-self: center;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: hsla(214.7,33.6%,48.43%,1) ;   
  } 
  