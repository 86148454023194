/* src/App.css */
/* Reset default margins and paddings */

/* Ensure the content is centered */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Viewport height */
}


body {
  margin: 0 auto;
  padding: 0%;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  display: flex; /* Use flexbox on the body */
  height: 100vh;
  align-items: flex-start; /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: #fff; /* Optional: light background color */
  background-image: url('./data/front-church.webp'); /* Replace with your image path */
  background-size: cover; /* Scale the image to cover the entire body */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-attachment: fixed; /* Keep the image fixed while scrolling */

}

.App {
  
  margin: 0 auto;
  padding: 2%;
  width: 95%;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  height: 95%;

  color:hsla(214.7,33.6%,48.43%,1) ;
}
.App p, h1, h2, h3, h4, h5, h6 {
  
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color:hsla(214.7,33.6%,48.43%,1) ;
}
/* Progress bar */

progress {
  width: 100%;
  height: 20px;
}

.navigation-buttons {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
}


.navigation-buttons button {
  margin: 10px;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
}
.progress-bar {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

progress {
  width: 100%;
  height: 20px;
}

.navigation-buttons {
  margin-top: 20px;
}


.scale-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.scale-bar span {
  width: 50%;
  text-align: center;
  font-weight: bold;
}
