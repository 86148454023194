.survey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  max-width: 95%;
  padding: 20px;
  background-color: #ffffff;
  margin: 0 auto; /* Center the container */
  border: 2px double hsla(214.7,33.6%,48.43%,1);
}

.survey-container h2, p {
  margin: 0 auto;
  padding: 0;
}
.survey-container h2  {
  font-weight: 400;
}

.survey-container h5 {
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 5px;
}

.survey-card {
  box-shadow: 0 0 0 !important;
  background-color: #ffffff !important;
}


@media (max-width: 768px) {
  .survey-container{
    margin: 0 auto;
    max-width: 100%;

  }
  .survey-card {
    width: 100%;
    height: auto;
    margin: 0;
    justify-content: space-between;
    padding: 10px; /* Smaller padding on mobile */
    min-height: 200px; /* Adjust card height for smaller screens */
  }
}

.survey-controls {
  margin-top: 5px;
  width: 100%;
  max-width: 100%;
}

.likelihood-indicator {
  margin-bottom: 10px;
  text-align: center; /* Center the text */
  
  background-color: hsla(214.7,33.6%,48.43%,1) !important;
}

.nav-button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.nav-button-group button {
  color: hsla(214.7,33.6%,48.43%,1);
  border: 1px solid hsla(214.7,33.6%,48.43%,1) !important;
}

.progress-bar-container {
  margin-top: 20px;
}

.progress-text {
  color: hsla(214.7,33.6%,48.43%,1);
  margin-top: 10px;
  text-align: center; /* Center the progress text */

}

/* User Info Form */
.user-info-form {
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 400px;
  margin-top: 0;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid  hsla(214.7,33.6%,48.43%,1);
  border-radius: 8px
}

.user-info-form .form-group {
  margin-bottom: 15px;
  
}

.user-info-form label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.user-info-form input[type="text"],
.user-info-form input[type="email"] {
  width: 95%;
  padding: 2.5%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-info-form input[type="checkbox"] {
  margin-right: 10px;
}

.user-info-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-info-form button:hover {
  background-color: #0056b3;
}
.ministry-option {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Spacing between options */
}

.ministry-option input {
  margin-right: 8px; /* Space between checkbox and text */
  transform: translateY(-2px); /* Adjust vertical alignment of the checkbox */
}

.ministry-option label {
  line-height: 1; /* Reset line height to match the checkbox size */
  align-self: center;
}
