/* QuestionComponent.css */

.question-and-button {
  display: block;
  width: 100%;
  flex-grow: 0;
  align-items: center;
  border-radius: 5px;
  min-width: 100%;
}

.question-text {
  margin: auto 0;
  text-align: center;
  justify-self: start;
  min-height: 150px;
  width: 100%;
}
.question-text h3 {
  font-size: 1.5rem;
  text-size-adjust: fit;
  font-weight: 400;
}

.frequency-buttons {
  display: block;
  flex-direction: column; /* Change to column for mobile */
  align-items: center;
  margin: 0 auto;
}


.frequency-buttons button {
  justify-self: unset;
  padding: 10px 30px !important;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Ensure buttons take up full width on mobile */
  background-color: hsla(214.7,33.6%,48.43%,1) ;    
}

