/* results.css */
.results-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
  justify-content: center; /* Centers child elements vertically */
  width: 95%;
  padding: 2%;
  margin: 0 auto;
  background-color: #fff;
  text-align: center;
  
  border: 2px double hsla(214.7,33.6%,48.43%,1);
}



.results-logo {
  width: 150px;
  height: auto;
  margin: 0 auto 5px; /* Centers logo and adds bottom margin */
}

.results-redirect {
  margin-top: 5px;
}

.results-top-three {
  text-align: center;
  margin: 5px;
}

.results-top-three h3 {
  font-size: 1.25rem;
  margin-bottom: 5px;
  text-decoration: underline;
}

.top-category {
  font-weight: bold;
  margin: 10px 0;
}

#first {
  font-size: 2.25rem;
}

#second {
  font-size: 1.5rem; 
}

#third {
  font-size: 1rem;
}
